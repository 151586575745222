import AsyncStorage from '@react-native-community/async-storage';
import * as Sentry from "@sentry/react";
import React, { createContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { subscriptionClient } from '../client/subscriptionClient';
import { persistor } from '../client/persisitor';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import {
  LoginWithPasswordMutation,
  useLoginWithPasswordMutation,
  useSetCurrentUserMutation,
} from '../types/apolloTypes';
import { useHistory, useLocation } from '../utils/routing';
import env from '@beam-australia/react-env';
interface AuthContextProps {
  isAuthenticated: boolean;
  checkingAuth: boolean;
  loggingIn: boolean;
  login?: (
    email: string,
    password: string,
  ) => Promise<LoginWithPasswordMutation>;
  logout?: (clearData?: boolean, redirect?: boolean) => void;
}

const defaultAuthContextProps: AuthContextProps = {
  isAuthenticated: false,
  checkingAuth: true,
  loggingIn: false,
};

export const AuthContext = createContext<AuthContextProps>(
  defaultAuthContextProps,
);

const clientId = env('AUTH0_CLIENT_ID');
const audience = env('AUTH0_AUDIENCE');

export let logoutGlobal: (
  clearData?: boolean,
  redirect?: boolean,
) => void | null = null;

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    defaultAuthContextProps.isAuthenticated,
  );
  const [checkingAuth, setCheckingAuth] = useState(
    defaultAuthContextProps.checkingAuth,
  );

  const client = useApolloClient();

  const [loginWithPassword, { loading }] = useLoginWithPasswordMutation({
    fetchPolicy: 'no-cache',
  });

  const [setCurrentUser] = useSetCurrentUserMutation();
  const history = useHistory();

  const checkAuthToken = async () => {
    setCheckingAuth(true);
    const token = await AsyncStorage.getItem('token');
    if (!!token) setIsAuthenticated(true);
    setCheckingAuth(false);
  };

  useEffect(() => {
    checkAuthToken();
    logoutGlobal = logout;

    return () => {
      logoutGlobal = null;
    };
  }, []);

  const location = useLocation();

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  const login = async (email, password) => {
    return loginWithPassword({
      variables: {
        input: {
          clientId,
          audience,
          scope: 'openid profile',
          username: email,
          password,
        },
      },
    }).then(({ data }) => {
      if (
        data?.loginWithPassword?.__typename ===
        'SuccessfulAuthenticationPayload'
      ) {
        setCurrentUser({
          variables: {
            // @ts-ignore
            user: data.loginWithPassword.user,
          },
        });
        
        if (data?.loginWithPassword?.__typename === 'SuccessfulAuthenticationPayload'){
          Sentry.getCurrentScope().setUser('SuccessfulAuthenticationPayload', { 
            id: data?.loginWithPassword?.user?.id,
            email: data?.loginWithPassword?.user?.email,
            firstName: data?.loginWithPassword?.user?.givenName,
            lastName: data?.loginWithPassword?.user?.familyName
          });
        }

        AsyncStorage.setItem('token', data.loginWithPassword.accessToken).then(
          () => {
            if (location.pathname === '/') {
              history.push('/?loggedIn=true');
              updateAllBreadcrumbs([
                {
                  name: 'dashboard',
                  to: '/',
                },
              ]);
            }
            setIsAuthenticated(true);
          },
        );
      }
      return data;
    });
  };

  const logout = (clearData, redirect) => {
    AsyncStorage.removeItem('token').then(() => {
      setIsAuthenticated(false);
      if (redirect) {
        history.push('/');
      }

      subscriptionClient.close();

      if (clearData) {
        client.clearStore();
        persistor.purge();
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        checkingAuth,
        loggingIn: loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
